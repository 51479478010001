
























































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import Loading from '@/components/Loading.vue'
import BackLink from '@/components/BackLink.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import PlaceInput from '@/components/inputs/PlaceInput.vue'
import SignupPhysicianCard from '@/components/medications/SignupPhysicianCard.vue'
import { usPhoneNumber } from '@/util/validators/phoneNumberValidator'
import PrescriberInfo from '@/components/medications/PrescriberInfo.vue'
import { PrescriberSearchQuery } from '@/vuex/physicians'
import { $tc } from '@/i18n'

@Component({
  components: {
    PhoneInput,
    PlaceInput,
    Loading,
    BackLink,
    SignupPhysicianCard,
    PrescriberInfo,
  },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    firstName: {},
    location: { required },
    distance: { required, numeric },
    phone: { usPhoneNumber },
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError', 'hasSucceeded']),
    ...mapState('physicians', ['physicians', 'searchQuery']),
  },
})
export default class PrescriberSearch extends Vue {
  isInProgress!: (key: string) => boolean
  searchQuery!: PrescriberSearchQuery
  firstName = ''
  lastName = ''
  location = ''
  phone = ''
  distance = 10
  distances = [1, 5, 10, 20, 50].map(dist => ({
    value: dist,
    text: $tc('{count} mile | {count} miles', dist),
  }))

  async validate(): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return
    }
    await this.$store.dispatch('physicians/searchByLocation', {
      firstName: this.firstName,
      lastName: this.lastName,
      location: this.location,
      distance: this.distance,
      phone: this.phone,
    })
    this.$router.push({ path: 'results' })
  }

  get loading(): boolean {
    return this.isInProgress('physicians/searchByLocation') || this.isInProgress('physicians/add')
  }

  async created() {
    this.firstName = this.searchQuery.firstName ?? ''
    this.phone = this.searchQuery.phone ?? ''
    this.distance = this.searchQuery.distance ?? 10
    this.location = this.searchQuery.location ?? ''

    this.lastName = this.searchQuery.lastName
  }
}
