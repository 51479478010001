
























import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Physician from '@/models/Physician'
import Loading from '@/components/Loading.vue'
import SignupPhysicianCard from '@/components/medications/SignupPhysicianCard.vue'
import AddRxMedHeader from '@/components/medications/addRx/MedHeader.vue'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import SignupPhysician from '@/models/SignupPhysician'

@Component({
  components: {
    Loading,
    SignupPhysicianCard,
    AddRxMedHeader,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
    ...mapGetters('addRx', ['medStrength']),
    ...mapState('addRx', ['prescriber', 'prescriberDetails']),
  },
})
export default class PrescriberConfirm extends Vue {
  isInProgress!: (key: string) => boolean
  prescriber!: Physician
  prescriberDetails?: SignupPhysician
  medStrength!: PrescriptionStrength
}
