

































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import Physician from '@/models/Physician'
import Loading from '@/components/Loading.vue'
import BackLink from '@/components/BackLink.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import SignupPhysicianCard from '@/components/medications/SignupPhysicianCard.vue'
import PrescriberInfo from '@/components/medications/PrescriberInfo.vue'
import { PrescriberSearchQuery } from '@/vuex/physicians'

@Component({
  components: {
    PhoneInput,
    Loading,
    BackLink,
    LinkArrowIcon,
    SignupPhysicianCard,
    PrescriberInfo,
  },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    lastName: { required },
    location: { required },
    selected: { required, numeric },
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError', 'hasSucceeded']),
    ...mapState('physicians', ['physicians', 'searchQuery', 'physicianSearchResults']),
  },
})
export default class PrescriberResults extends Vue {
  isInProgress!: (key: string) => boolean
  hasSucceeded!: (key: string) => boolean
  physicianSearchResults!: Physician[]
  searchQuery!: PrescriberSearchQuery
  currentPage = 1
  perPage = 10

  get loading(): boolean {
    return this.isInProgress('physicians/searchByLocation') || this.isInProgress('physicians/add')
  }

  get currentPageResults() {
    const from = (this.currentPage - 1) * this.perPage
    const to = Math.min(this.currentPage * this.perPage, this.physicianSearchResults.length)
    return this.physicianSearchResults.slice(from, to)
  }

  get addManualRoute() {
    return {
      path: 'add-manually',
      query: {
        firstName: this.searchQuery.firstName,
        lastName: this.searchQuery.lastName,
        phone: this.searchQuery.phone,
      },
    }
  }

  get searchDoctorName() {
    const { firstName, lastName } = this.searchQuery
    return firstName ? `${firstName} ${lastName}` : lastName
  }

  async select(prescriber: Physician) {
    const physician = prescriber as Physician

    const savedPhysician = await this.$store.dispatch('physicians/add', { physician })

    if (this.$route.name === 'update-prescriber-results') {
      this.$store.dispatch('physicianUpdate/selectPhysician', savedPhysician)
      this.$router.push({ path: 'other' })
    } else {
      this.$store.dispatch('addRx/selectPrescriber', { prescriber: savedPhysician })
      this.$router.push({ path: 'confirm' })
    }
  }
}
