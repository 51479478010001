



































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import Loading from '@/components/Loading.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import PlaceInput from '@/components/inputs/PlaceInput.vue'
import SignupPhysicianCard from '@/components/medications/SignupPhysicianCard.vue'
import PrescriberInfo from '@/components/medications/PrescriberInfo.vue'
import User from '@/models/User'
import { PrescriberSearchQuery } from '@/vuex/physicians'
import { $tc } from '@/i18n'

@Component({
  components: {
    PhoneInput,
    PlaceInput,
    Loading,
    SignupPhysicianCard,
    PrescriberInfo,
  },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    lastName: { required },
    location: { required },
    distance: { required, numeric },
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError', 'hasSucceeded']),
    ...mapState('physicians', ['physicians', 'searchQuery']),
    ...mapState('user', ['me']),
  },
})
export default class PrescriberSearch extends Vue {
  isInProgress!: (key: string) => boolean
  me!: User
  searchQuery!: PrescriberSearchQuery
  lastName = ''
  location = ''
  distance = 10
  locationIsOpen = false
  distances = [1, 5, 10, 20, 50].map(dist => {
    return { value: dist, text: $tc('Within {count} mile | Within {count} miles', dist) }
  })

  showLocation() {
    this.locationIsOpen = true
  }

  async validate(): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return
    }
    await this.$store.dispatch('physicians/searchByLocation', {
      lastName: this.lastName,
      location: this.location,
      distance: this.distance,
    })
    this.$router.push({ path: 'results' })
  }

  get loading(): boolean {
    return (
      this.isInProgress('user/loadMe') ||
      this.isInProgress('physicians/searchByLocation') ||
      this.isInProgress('physicians/add')
    )
  }

  async created() {
    await this.$store.dispatch('user/ensureMe')
    this.lastName = this.searchQuery.lastName
    this.distance = this.searchQuery.distance
    this.location = (this.searchQuery.location || this.me.shippingAddress?.fiveDigitZipcode) ?? ''
  }
}
