



















import { Component, Vue, Prop } from 'vue-property-decorator'
import SignupPhysician from '@/models/SignupPhysician'

@Component
export default class SignupPhysicianCard extends Vue {
  @Prop({ type: Object })
  prescriber!: SignupPhysician

  @Prop({ type: Boolean, default: false })
  button?: boolean
}
