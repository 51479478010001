









































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Physician from '@/models/Physician'
import Loading from '@/components/Loading.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import AddRxMedHeader from '@/components/medications/addRx/MedHeader.vue'
import PrescriptionStrength from '@/models/PrescriptionStrength'

@Component({
  components: {
    Loading,
    LinkArrowIcon,
    AddRxMedHeader,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded', 'getError']),
    ...mapState('physicians', ['physicians']),
    ...mapGetters('addRx', ['medStrength']),
  },
})
export default class PrescriberSearch extends Vue {
  hasSucceeded!: (key: string, id?: string) => boolean
  physicians!: Physician[]
  medStrength!: PrescriptionStrength

  selectPrescriber(physician: Physician) {
    this.$store.dispatch('addRx/selectPrescriber', { prescriber: physician })
    this.$router.push({ name: 'pharmacy' })
  }

  async mounted() {
    await this.$store.dispatch('physicians/load')
    if (this.hasSucceeded('physicians/load')) {
      if (!this.physicians.length) {
        this.$router.replace('search')
      }
    }
  }
}
