import { render, staticRenderFns } from "./PrescriberRefine.vue?vue&type=template&id=909411be&scoped=true&"
import script from "./PrescriberRefine.vue?vue&type=script&lang=ts&"
export * from "./PrescriberRefine.vue?vue&type=script&lang=ts&"
import style0 from "./PrescriberRefine.vue?vue&type=style&index=0&id=909411be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "909411be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BFormInput } from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'
import IconSearch from '@/assets/icons/icon-search.svg'
installComponents(component, {BButton,BForm,BFormGroup,BFormInput,BFormSelect,IconSearch})
