


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { validationStateMixin } from '@/util/validationState'
import { usPhoneNumber } from '@/util/validators/phoneNumberValidator'
import Loading from '@/components/Loading.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import SignupPhysician from '@/models/SignupPhysician'

@Component({
  components: {
    PhoneInput,
    Loading,
  },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    firstName: { required },
    lastName: { required },
    phone: { required, usPhoneNumber },
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class PrescriberAddManually extends Vue {
  isInProgress!: (key: string) => boolean

  @Prop(String)
  initialFirstName?: string

  @Prop(String)
  initialLastName!: string

  @Prop(String)
  initialPhone!: string

  firstName = this.initialFirstName || ''
  lastName = this.initialLastName || ''
  phone = this.initialPhone || ''

  async validate(): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return
    }
    await this.savePhysician()
    this.nextStep()
  }

  async savePhysician() {
    const physician = new SignupPhysician()
    physician.firstName = this.firstName
    physician.lastName = this.lastName
    physician.phoneNumber = this.phone

    const savedPhysician = await this.$store.dispatch('physicians/add', { physician })

    if (this.$route.name === 'update-prescriber-add-manually') {
      return this.$store.dispatch('physicianUpdate/selectPhysician', savedPhysician)
    } else {
      return this.$store.dispatch('addRx/selectPrescriber', { prescriber: savedPhysician })
    }
  }

  async nextStep() {
    // save the current info in query params
    this.$router.replace({
      query: {
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
      },
    })

    await this.$nextTick()

    if (this.$route.name === 'update-prescriber-add-manually') {
      this.$router.push({ path: 'other' })
    } else {
      this.$router.push({ path: 'confirm' })
    }
  }

  get loading() {
    return this.isInProgress('physicians/add')
  }
}
